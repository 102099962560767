import ConfigureVue from './Tasks/ConfigureVue'
import ConfigureAxios from './Tasks/ConfigureAxios'
import AddGlobalBindings from './Tasks/AddGlobalBindings'
import ResolveVueOptions from './Tasks/ResolveVueOptions'
import AddRouterMiddleware from './Tasks/AddRouterMiddleware'
import BootServiceProviders from './Tasks/BootServiceProviders'
import InitializeStreamChat from './Tasks/InitializeStreamChat'
import RegisterServiceProviders from './Tasks/RegisterServiceProviders'
import StartBugsnagErrorReporting from './Tasks/StartBugsnagErrorReporting'
import EstablishWebSocketConnection from './Tasks/EstablishWebSocketConnection'

/**
 * The application's bootstrapping engine.
 *
 * @author Erik Galloway <egalloway@claruscare.com>
 */
export default class Engine {
	/**
	 * Create a new Engine instance.
	 *
	 * @param {Registrar} registrar
	 */
	constructor(registrar) {
		/**
		 * The service provider registrar instance.
		 *
		 * @type {Registrar}
		 */
		this.registrar = registrar
	}

	/**
	 * Bootstrap and start the up the application.
	 *
	 * @return {Promise}
	 */
	async start() {
		await this._promise(new AddGlobalBindings(this.registrar))
		await this._promise(new RegisterServiceProviders(this.registrar))
		await this._promise(new ConfigureAxios(this.registrar))
		await this._promise(new InitializeStreamChat(this.registrar))
		await this._promise(new EstablishWebSocketConnection(this.registrar))
		await this._promise(new BootServiceProviders(this.registrar))
		await this._promise(new ConfigureVue(this.registrar))
		await this._promise(new AddRouterMiddleware(this.registrar))
		await this._promise(new StartBugsnagErrorReporting(this.registrar))
		return await this._promise(new ResolveVueOptions(this.registrar))
	}

	/**
	 * Create a new start-up task promise.
	 *
	 * @param {Task} task
	 * @return {Promise}
	 */
	_promise(task) {
		return new Promise(resolve => task.run(resolve))
	}
}
